'use client'

// React Imports
import { useEffect, useState } from 'react'

// Next Imports
import { redirect, useParams, useRouter, useSearchParams } from 'next/navigation'

// MUI Imports
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Divider from '@mui/material/Divider'

// Third-party Imports
import classnames from 'classnames'

// Component Imports
import Link from '@components/Link'
import Logo from '@components/layout/shared/Logo'
import CustomTextField from '@core/components/mui/TextField'

// Config Imports
import themeConfig from '@configs/themeConfig'

// Hook Imports
import { useImageVariant } from '@core/hooks/useImageVariant'
import { useSettings } from '@core/hooks/useSettings'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import { APIURL, EncryptedValue } from '@/views/common/commonUrl'

// Util Imports
import { getLocalizedUrl } from '@/utils/i18n'
import { useCookie } from 'react-use'
import _ from 'lodash'

const axios = require('axios');

// Styled Custom Components
const LoginIllustration = styled('img')(({ theme }) => ({
	zIndex: 2,
	blockSize: 'auto',
	maxBlockSize: 680,
	maxInlineSize: '100%',
	margin: theme.spacing(12),
	[theme.breakpoints.down(1536)]: {
		maxBlockSize: 550
	},
	[theme.breakpoints.down('lg')]: {
		maxBlockSize: 450
	}
}))

const MaskImg = styled('img')({
	blockSize: 'auto',
	maxBlockSize: 355,
	inlineSize: '100%',
	position: 'absolute',
	insetBlockEnd: 0,
	zIndex: -1
})

const LoginV2 = ({ mode, cookieData }) => {
	const delay = ms => new Promise(res => setTimeout(res, ms));
	// States
	const [isPasswordShown, setIsPasswordShown] = useState(false)
	const [error, setError] = useState({
		status: false,
		text: ''
	})
	const [username, setUsername] = useState()
	const [password, setPassword] = useState()
	const [btnDisable, setBtnDisable] = useState(false)

	const [resetPassword, setResetPassword] = useState(false)
	const [resetPasswordData, setResetPasswordData] = useState({
		password: '',
		confirmPassword: '',
		isPasswordShown: false,
		isConfirmPasswordShown: false,
	})

	const [reloadFlag, setReloadFlag] = useState(true)

	// Vars
	const darkImg = '/images/pages/auth-mask-dark.png'
	const lightImg = '/images/pages/auth-mask-light.png'
	const darkIllustration = '/images/illustrations/auth/v2-login-dark.png'
	const lightIllustration = '/images/illustrations/auth/v2-login-light.png'
	const borderedDarkIllustration = '/images/illustrations/auth/v2-login-dark-border.png'
	const borderedLightIllustration = '/images/illustrations/auth/v2-login-light-border.png'

	// Hooks
	const router = useRouter()
	const { settings, updateSettings } = useSettings()
	const searchParams = useSearchParams()
	const { lang: locale } = useParams()
	const theme = useTheme()
	const hidden = useMediaQuery(theme.breakpoints.down('md'))
	const authBackground = useImageVariant(mode, lightImg, darkImg)

	const characterIllustration = useImageVariant(
		mode,
		lightIllustration,
		darkIllustration,
		borderedLightIllustration,
		borderedDarkIllustration
	)

	const handleClickShowPassword = () => setIsPasswordShown(show => !show)

	useEffect(() => {
		document.cookie.split(';').forEach(cookie => {
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		});
		// caches.keys().then((names) => {
		// 	console.log(names);
		// 	names.forEach((name) => {
		// 		caches.delete(name);
		// 	});
		// });
	}, [])


	useEffect(() => {
		const listener = async (event) => {
			if (event.code === "Enter" || event.code === "NumpadEnter") {
				// console.log("Enter key was pressed. Run your function.");
				// await delay(1000)		
				if (resetPassword) {
					handleReset();
				} else {
					handleLogin();
				}

				event.preventDefault();
			}
		};
		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
	}, [username, password, resetPasswordData]);


	const handleLogin = async () => {
		if (username && password) {
			setBtnDisable(true)
			var ip = ''
			let configs = {
				method: 'get',
				url: 'https://api.ipify.org/?format=json',
				headers: {
					'Content-Type': 'application/json',
				},
			};
			var pwd = await EncryptedValue(password)
			await axios.request(configs)
				.then((response) => {
					ip = response.data.ip
				})

			let data = JSON.stringify({
				"User": username,
				"Password": pwd,
				"Device": "Web",
				"Ip": ip
			});

			let config = {
				method: 'post',
				url: APIURL + 'LoginAccess',
				headers: {
					'Content-Type': 'application/json',
				},
				data: data
			};

			await axios.request(config)
				.then((response) => {
					var result = response.data
					if (result.length > 0) {
						updateSettings({ selectedUser: {}, superadmin: false })

						if (result[0]['ApiStatus'] == true) {
							if (result[0]['AType'] == 'Admin') {
								var branchFamilyArr = []
								if (result[0]['Branch']) {
									result[0]['Branch'].forEach(element => {
										var obj = {
											name: element.Name,
											type: 'Branch'
										}
										branchFamilyArr.push(obj)
									});
									result[0]['Family'].forEach(element => {
										var obj = {
											name: element.Name,
											type: 'Family'
										}
										branchFamilyArr.push(obj)
									});
								}

								var activeClientArr = []
								if (result[0]['ActiveClient']) {
									result[0]['ActiveClient'].forEach(element => {
										activeClientArr.push({
											code: element.ACode,
											name: element.AName,
										})
									})
								}

								var obj = {
									"username": username,
									"dispname": result[0]['AName'],
									"type": result[0]['AType'],
									"token": result[0]['SessKey'],
									"branchfamily": branchFamilyArr,
								}
								updateSettings({ accesstoken: result[0]['SessKey'], type: result[0]['AType'], superadmin: result[0]['SuperAdmin'] == 'Yes' ? true : false })
								// updateCookieKalpUser(obj)
								document.cookie = `KalpUser=${JSON.stringify(obj)}`;
								// document.cookie = `KalpActiveClient=${JSON.stringify(activeClientArr)}`;
								// localStorage.setItem('KalpActiveClient', JSON.stringify(activeClientArr))

								const redirectURL = searchParams.get('redirectTo') ?? '/home'
								router.push(redirectURL)
								return toast.success('Successfully Logged in!', {
									position: 'bottom-right'
								})

							} else {
								var obj = {
									"username": username,
									"dispname": result[0]['AName'],
									"type": result[0]['AType'],
									"token": result[0]['SessKey'],
								}

								updateSettings({ selectedUser: { label: username + " - " + result[0]['AName'], value: username }, accesstoken: result[0]['SessKey'], type: result[0]['AType'] })

								// updateCookieKalpUser(JSON.stringify(obj))
								document.cookie = `KalpUser=${JSON.stringify(obj)}`;
								// localStorage.setItem('KalpUser', JSON.stringify(obj))


								const redirectURL = searchParams.get('redirectTo') ?? '/client-dashboard'
								router.push(redirectURL)
								return toast.success('Successfully Logged in!', {
									position: 'bottom-right'
								})
							}

						} else if (result[0]['Remark'] == 'Default Password, Required To Enter New Password') {
							setResetPassword(true)
							toast.success(result[0]['Remark'], {
								position: 'bottom-right'
							})
							setBtnDisable(false)
						} else if (result[0]['ApiStatus'] == false) {
							toast.error(result[0]['Remark'], {
								position: 'bottom-right'
							})
							setBtnDisable(false)
						} else {
							setError(prevState => ({ ...prevState, status: true, text: 'Something went wrong!' }))
							return false
						}

					} else {
						setBtnDisable(false)
						setError(prevState => ({ ...prevState, status: true, text: result[0]['Remark'] }))
						return false
					}

				})
				.catch((error) => {
					setBtnDisable(false)
					console.log(error)
					setError(prevState => ({ ...prevState, status: true, text: 'Internal Server Error!' }))
					return false
				});
		} else {
			setBtnDisable(false)
			setError(prevState => ({ ...prevState, status: true, text: 'Please Enter Username & Password!' }))
			return false;
		}
	}


	const handleReset = async () => {
		if (resetPasswordData.password != '' && resetPasswordData.confirmPassword != "") {
			if (resetPasswordData.password == resetPasswordData.confirmPassword) {
				var pwd = await EncryptedValue(resetPasswordData.password)
				let data = JSON.stringify({
					"User": username,
					"Password": pwd
				});
				let config = {
					method: 'post',
					url: APIURL + 'ResetPassword',
					headers: {
						'Content-Type': 'application/json'
					},
					data: data
				};

				axios.request(config)
					.then((response) => {
						var result = response.data
						if (result.length > 0) {
							if (result[0]['ApiStatus'] == true) {
								setResetPassword(false)
								setError(prevState => ({ ...prevState, status: false }))
								setUsername('')
								setPassword('')
								toast.success(result[0]['Remark'], {
									position: 'bottom-right'
								})
							} else {
								setError(prevState => ({ ...prevState, status: true, text: 'Something went wrong!' }))
								return false
							}
						} else {
							setError(prevState => ({ ...prevState, status: true, text: result[0]['Remark'] }))
							return false
						}
					})
					.catch((error) => {
						console.log(error)
						setError(prevState => ({ ...prevState, status: true, text: 'Internal Server Error!' }))
						return false
					});
			} else {
				setError(prevState => ({ ...prevState, status: true, text: 'Password & Confirm Password Does Not Match!' }))
			}
		}
	}

	return (
		<div className='flex bs-full justify-center'>
			<ToastContainer />
			<div
				className={classnames(
					'flex bs-full items-center justify-center flex-1 min-bs-[100dvh] relative p-6 max-md:hidden',
					{
						'border-ie': settings.skin === 'bordered'
					}
				)}
			>
				<LoginIllustration src={characterIllustration} alt='character-illustration' />
				{!hidden && (
					<MaskImg
						alt='mask'
						src={authBackground}
						className={classnames({ 'scale-x-[-1]': theme.direction === 'rtl' })}
					/>
				)}
			</div>
			<div className='flex justify-center items-center bs-full bg-backgroundPaper !min-is-full p-6 md:!min-is-[unset] md:p-12 md:is-[480px]'>
				<div className='flex flex-col gap-6 is-full sm:is-auto md:is-full sm:max-is-[400px] md:max-is-[unset] mbs-11 sm:mbs-14 md:mbs-0'>
					<div className='flex flex-col gap-1'>
						<Logo />
						<Typography variant='h4'>{`Welcome to ${themeConfig.templateName}! 👋🏻`}</Typography>
						<Typography>Please sign-in to your account and start the adventure</Typography>
					</div>
					{error.status && <div className='flex flex-col gap-1'>
						<Typography variant='body1' color={'error'}>{error.text}</Typography>
					</div>}
					{resetPassword == false && <form
						noValidate
						autoComplete='off'
						// onSubmit={e => {
						//   e.preventDefault()
						//   router.push('/')
						// }}
						className='flex flex-col gap-5'
					>
						<CustomTextField autoFocus fullWidth label='Username' placeholder='Enter your username' value={username} onChange={(e) => setUsername(e.target.value)} />
						<CustomTextField
							fullWidth
							label='Password'
							placeholder='············'
							id='outlined-adornment-password'
							type={isPasswordShown ? 'text' : 'password'}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton edge='end' onClick={handleClickShowPassword} onMouseDown={e => e.preventDefault()}>
											<i className={isPasswordShown ? 'tabler-eye-off' : 'tabler-eye'} />
										</IconButton>
									</InputAdornment>
								)
							}}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>

						<Button fullWidth variant='contained'
							disabled={btnDisable}
							onClick={() =>
								handleLogin()
							}>
							Login
						</Button>
					</form>}

					{resetPassword && <form
						noValidate
						autoComplete='off'
						className='flex flex-col gap-5'
					>

						<CustomTextField
							fullWidth
							label='Password'
							placeholder='············'
							id='outlined-adornment-password'
							type={resetPasswordData.isPasswordShown ? 'text' : 'password'}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton edge='end' onClick={() => setResetPasswordData(prevState => ({ ...prevState, isPasswordShown: resetPasswordData.isPasswordShown ? false : true }))} onMouseDown={e => e.preventDefault()}>
											<i className={resetPasswordData.isPasswordShown ? 'tabler-eye-off' : 'tabler-eye'} />
										</IconButton>
									</InputAdornment>
								)
							}}
							value={resetPasswordData.password}
							onChange={(e) => setResetPasswordData(prevState => ({ ...prevState, password: e.target.value }))}
						/>
						<CustomTextField
							fullWidth
							label='Confirm Password'
							placeholder='············'
							id='outlined-adornment-password'
							type={resetPasswordData.isConfirmPasswordShown ? 'text' : 'password'}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton edge='end' onClick={(show) => setResetPasswordData(prevState => ({ ...prevState, isConfirmPasswordShown: resetPasswordData.isConfirmPasswordShown ? false : true }))} onMouseDown={e => e.preventDefault()}>
											<i className={resetPasswordData.isConfirmPasswordShown ? 'tabler-eye-off' : 'tabler-eye'} />
										</IconButton>
									</InputAdornment>
								)
							}}
							value={resetPasswordData.confirmPassword}
							onChange={(e) => setResetPasswordData(prevState => ({ ...prevState, confirmPassword: e.target.value }))}
						/>

						<Button fullWidth variant='contained' onClick={() =>
							handleReset()
						}>
							Reset Password
						</Button>
					</form>
					}
				</div>
			</div>
		</div>
	)
}

export default LoginV2
